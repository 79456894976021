var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "row-bg", staticStyle: { padding: "12px" } },
        [
          _c(
            "div",
            [
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("新增知识点："),
              ]),
              _c("el-input", {
                staticStyle: { width: "20%" },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addKnowledge },
                },
                [_vm._v("增加")]
              ),
              _c("hr"),
              _vm._l(_vm.tagList, function (item) {
                return _c(
                  "el-tag",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "warning" },
                  },
                  [_vm._v(_vm._s(item.content))]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }