<template>
  <div>
    <el-row class="row-bg" style="padding: 12px;">
    <div >
      <span style="font-size: 14px;">新增知识点：</span>
      <el-input v-model="inputValue" style="width: 20%;"></el-input>
      <el-button type="primary" size="small" style="margin-left: 20px;" @click="addKnowledge">增加</el-button>
      <hr/>
      <el-tag style="margin-left: 10px;"  v-for="(item) in tagList" type="warning">{{item.content}}</el-tag>
    </div>
    </el-row>
  </div>
</template>


<script>
  import { saveKnowledgePoint, listKnowledgePoint } from '@/api/research/knowledge'
    export default {
        name: "knowledge",
        data() {
          return {
            inputValue:'',
            tagList:[]
          }
        },
        created(){
          this.listKnowledgePoint()
        },
        methods: {
          async listKnowledgePoint() {
            const res  = await listKnowledgePoint({
              pageNum:1,
              pageSize:100000,
              needCount:true
            })
            if(res && res.state === 'success') {
              this.tagList = res.body.list
            }
          },
          async addKnowledge() {
            if (this.inputValue.trim() === '') {
              window.$msg('知识点内容不能为空',2)
              return false
            }
          const response = await saveKnowledgePoint({
            content:this.inputValue
          })
            if(response && response.state === 'success') {
              window.$msg('保存成功');
              this.inputValue = ''
              this.listKnowledgePoint()
            }
          }
        }
    }
</script>


<style lang="scss" scoped>
  @import "../../style/container.scss";
</style>
